<template>
  <div class="greybackgroundmatchscreen">
    <div v-if="showCurrentTask">
      <div class="mb-6 mt-4 d-flex justify-content-between">
        <div class="col-xl-9">
          <div class="d-flex">
            <div style="display: flex;">
              <button class="backbutton" @click="importGoback"><i class="fas fa-arrow-left"
                  style='color:#000000;'></i></button>
            </div>
            <div class="d-flex">
              <div class="matchbox">
                <inline-svg class="matchicon" width="30" height="30" src="media/svg/icons/General/matched_fileicon.svg" />
              </div>
            </div>
            &nbsp;&nbsp; <div style="display: flex;align-items: center;margin-top: 12px;">
              <p class="filename">{{ currentTask.title }}</p>
            </div>
          </div>
        </div>


        <div class="col-xl-3" style="display: flex; align-items: center;justify-content: end;">
          <!-- <b-row> -->
          <!-- <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col> -->
          <!-- <b-col cols="4"> -->
          <div v-if="currentTask.stage == 'Finished'" class="">
            <button data-v-step="purchase" @click="purchaseModalShow = !purchaseModalShow"
              class="btn btn-primary purchasebutton">
              Purchase Cleaned Data
            </button>
          </div>
          <div v-else-if="currentTask.stage == 'Paid'">
            <button data-v-step="paid" @click="downloadFile()" type="reset" class="btn btn-primary purchasebutton mb-5"
              ref="kt_save_changes">
              Download Your Data
            </button>

            <button data-v-step="paid" @click="downloadModalShow = !downloadModalShow" type="reset"
              class="btn btn-success purchasebutton" ref="kt_save_changes">
              Customise Export
            </button> <!-- <button data-v-step="paid" type="reset" class="btn btn-primary purchasebutton" ref="kt_save_changes"
              @click="downloadFile()" v-if="currentTask.stage == 'Paid'">
              Download Your Data
            </button> -->
          </div>
          <!-- </b-col>
          </b-row> -->
        </div>
      </div>

      <div class="row">
        <b-row class="DashboardReview-HeaderRow">



          <!-- <a href="/media/csv/Sample_RMD_Export_Layout.csv"
          v-on:click="setActiveTab"
          class="sampledata"
          data-toggle="tab"
          data-tab="1"
          download="Sample_RMD_Export_Layout.csv">
        <button type="" class="btn btn-success mr-2 dashboard-action-button" ref="kt_save_changes"> Sample RMD Export </button>
      </a>			 -->

          <b-col>
            <button type="reset" class="btn btn-success mr-2 Review-Action-Data-Button" ref="kt_view_stats"
              @click="doStats()" style="height: 50px;width: 142px;">
              View Stats
            </button>
          </b-col>
          <b-col>
            <button type="reset" class="btn btn-success mr-2 Review-Action-Data-Button Review-Action-Data-Button-selected"
              ref="kt_view_stats_data" @click="doData()" style="height: 50px;width: 206px;">
              View Sample Data</button>
          </b-col>
          <b-col>
            <button data-v-step="stats" type="reset"
              class="btn btn-success mr-2 Review-Action-Data-Button Review-Action-Data-Button w-10" ref="kt_view_stats"
              style="height: 50px;width: 210px;" @click="downloadSampleFile">
              Download Sample
            </button>
          </b-col>
        </b-row>

      </div>

      <!-- <b-row class=""> -->
      <div class="">
        <div class="dashboarmapp">


          <!-- <b-col class="dashboarmapp-left-pad"> -->
          <div class="DashboardReviewMatch-Map-your-Data mb-2">Your Sample Data</div>
          <div class="d-flex">
            <p class="DashboardReviewMatch-Map-your-Text">
              Below is a sample of your data and shows how the data has been
              imporved, to view the improvements please click on the hightlights
              fields to see the changes. Your data will be exported in .xlsx format. To see how this will be
              laid out, please
              <!-- <u>
              <a
                style="color: #3f6f86; font-size: 100%"
                href="#"
                @click="downloadSampleFile"
                >
                click here</a>
                href="/media/template/Sample_RMD_Export_Layout.csv"
                  download="Sample_RMD_Export_Layout.csv"
            </u> -->
              to view a sample export layout.
            </p>
          </div>

          <!-- </b-col> -->
          <!-- <b-col>
          <div class="datareviewDataselectionBox">
            <b-form-radio-group
              id="radio-slots"
              v-model="showDataTab"
              name="radio-options-slots"
              class="datareviewselectradiobuttons"
            >
              Radios in this slot will appear first
              <b-row>
                <b-col>
                  <b-form-radio
                    value="1"
                    :class="showDataTab == 1 ? 'radiohighlighttext' : ''"
                    >Contacts</b-form-radio
                  >
                </b-col>
                <b-col>
                  <b-form-radio
                    :value="2"
                    :class="showDataTab == 2 ? 'radiohighlighttext' : ''"
                    >Addresses</b-form-radio
                  >
                </b-col>
                <b-col>
                  <b-form-radio
                    :value="3"
                    :class="showDataTab == 3 ? 'radiohighlighttext' : ''"
                    >Phone Numbers</b-form-radio
                  >
                </b-col>
                <b-col>
                  <b-form-radio
                    :value="4"
                    :class="showDataTab == 4 ? 'radiohighlighttext' : ''"
                    >Email</b-form-radio
                  >
                </b-col>
              </b-row>
            </b-form-radio-group>
          </div>
        </b-col> -->
          <!-- </b-row> -->
        </div>
        <div>
          <b-col>
            <div class="datareviewDataselectionBox">
              <b-form-radio-group id="radio-slots" v-model="showDataTab" name="radio-options-slots"
                class="datareviewselectradiobuttons">
                <!-- Radios in this slot will appear first -->
                <div class="d-flex mt-5">
                  <div class="mr-15">
                    <b-form-radio value="1" :class="showDataTab == 1 ? 'radiohighlighttext' : ''">Contacts</b-form-radio>
                  </div>
                  <div class="mr-15">
                    <b-form-radio :value="2"
                      :class="showDataTab == 2 ? 'radiohighlighttext' : ''">Addresses</b-form-radio>
                  </div>
                  <div class="mr-15">
                    <b-form-radio :value="3" :class="showDataTab == 3 ? 'radiohighlighttext' : ''">Phone
                      Numbers</b-form-radio>
                  </div>
                  <div>
                    <b-form-radio :value="4" :class="showDataTab == 4 ? 'radiohighlighttext' : ''">Email</b-form-radio>
                  </div>

                </div>
              </b-form-radio-group>
            </div>
          </b-col>
          <b-col></b-col>
        </div>
      </div>
      <b-row>
        <b-col class="dashboardreviewdatamapp-left-pad" v-if="showDataTab == 1">
          <div v-if="getContacts.length == 0" class="row m-1">
            <div class="col-md-6 mx-auto">
              <div class="font-size-h1 font-weight-bold text-center">
                No Contact Data was Improved
              </div>
            </div>
          </div>

          <div v-if="getContacts.length > 0" class="dummy-table-container overflow-auto">
            <div class="dummy-scroll-content">
              <b-table hover :items="getContacts" :fields="fieldsTab1" class="review-data-field-match-list">
                <template #cell(cleaned_NameTitle)="data">
                  <div :class="
                      data.item.cleaned_NameTitle != data.item.nameTitle
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.nameTitle == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                     displayChangeModal(
                          data.item.cleaned_NameTitle,
                          data.item.nameTitle,
                          'Title'
                        )">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_NameTitle }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_NameForename)="data">
                  <div :class="
                      data.item.cleaned_NameForename != data.item.nameForename
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.nameForename == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_NameForename,
                          data.item.nameForename,
                          'Forename'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_NameForename }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_NameSurname)="data">
                  <div :class="
                      data.item.cleaned_NameSurname != data.item.nameSurname
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.nameSurname == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_NameSurname,
                          data.item.nameSurname,
                          'Surname'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_NameSurname }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_NameInitials)="data">
                  <div :class="
                      data.item.cleaned_NameInitials != data.item.nameInitials
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.nameInitials == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_NameInitials,
                          data.item.nameInitial,
                          'Initials'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_NameInitials }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_NameSalutation)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_NameSalutation }}
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-col class="dashboardreviewdatamapp-left-pad" v-if="showDataTab == 2">
          <div v-if="getAddresses.length == 0" class="row m-1">
            <div class="col-md-6 mx-auto">
              <div class="font-size-h1 font-weight-bold text-center">
                No Address Data was Improved
              </div>
            </div>
          </div>

          <div v-if="getAddresses.length > 0" class="dummy-table-container overflow-auto">
            <div class="dummy-scroll-content">
              <b-table hover :items="getAddresses" :fields="fieldsTab2" class="review-data-field-match-list">
                <template #cell(cleaned_OrganisationName)="data">
                  <div :class="
                      data.item.cleaned_OrganisationName != data.item.organisationName
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.organisationName == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_OrganisationName,
                          data.item.organisationName,
                          'Organisation Name'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_OrganisationName }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressLine1)="data">
                  <div :class="
                      data.item.cleaned_AddressLine1 != data.item.addressLine1
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressLine1 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_AddressLine1,
                          data.item.addressLine1,
                          'Address Line 1'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressLine1 }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressLine2)="data">
                  <div :class="
                      data.item.cleaned_AddressLine2 != data.item.addressLine2
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressLine2 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_AddressLine2,
                          data.item.addressLine2,
                          'Address Line 2'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressLine2 }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressLine3)="data">
                  <div :class="
                      data.item.cleaned_AddressLine3 != data.item.addressLine3
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressLine3 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_AddressLine3,
                          data.item.addressLine3,
                          'Address Line 3'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressLine3 }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressCounty)="data">
                  <div :class="
                      data.item.cleaned_AddressCounty != data.item.addressCounty
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressCounty == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_AddressCounty,
                          data.item.addressCounty,
                          'Address County'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressCounty }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressTown)="data">
                  <div :class="
                      data.item.cleaned_AddressTown != data.item.addressTown
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressTown == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
                        displayChangeModal(
                          data.item.cleaned_AddressTown,
                          data.item.addressTown,
                          'Address Town'
                        )
                      ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressTown }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_AddressPostcode)="data">
                  <div :class="
                      data.item.cleaned_AddressPostcode != data.item.addressPostcode
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.addressPostcode == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_AddressPostcode,
            data.item.addressPostcode,
            'Address Postcode'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_AddressPostcode }}
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-col class="dashboardreviewdatamapp-left-pad" v-if="showDataTab == 3">
          <div v-if="getPhones.length == 0" class="row m-1">
            <div class="col-md-6 mx-auto">
              <div class="font-size-h1 font-weight-bold text-center">
                No Phone Data was Improved
              </div>
            </div>
          </div>

          <div v-if="getPhones.length > 0" class="dummy-table-container overflow-auto">
            <div class="dummy-scroll-content">
              <b-table hover :items="getPhones" :fields="fieldsTab3" class="review-data-field-match-list">
                <template #cell(cleaned_Telephone1)="data">
                  <div :class="
                      data.item.cleaned_Telephone1 != data.item.telephone1
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.telephone1 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_Telephone1,
            data.item.telephone1,
            'Telephone 1'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone1 }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone1_Type)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone1_Type }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone1_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone1_Status === "VALID" ? "SYNTAX OK" : "" }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_Telephone2)="data">
                  <div :class="
                      data.item.cleaned_Telephone2 != data.item.telephone2
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.telephone2 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_Telephone2,
            data.item.telephone2,
            'Telephone 2'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone2 }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone2_Type)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone2_Type }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone2_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone2_Status === "VALID" ? "SYNTAX OK" : "" }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_Telephone3)="data">
                  <div :class="
                      data.item.cleaned_Telephone3 != data.item.telephone3
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.telephone3 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_Telephone3,
            data.item.telephone3,
            'Telephone 3'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone3 }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone3_Type)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone3_Type }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Telephone3_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Telephone3_Status === "VALID" ? "SYNTAX OK" : "" }}
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-col class="dashboardreviewdatamapp-left-pad" v-if="showDataTab == 4">
          <div v-if="getEmails.length == 0" class="row m-1">
            <div class="col-md-6 mx-auto">
              <div class="font-size-h1 font-weight-bold text-center">
                No Email Data was Improved
              </div>
            </div>
          </div>

          <div v-if="getEmails.length > 0" class="dummy-table-container overflow-auto">
            <div class="dummy-scroll-content">
              <b-table hover :items="getEmails" :fields="fieldsTab4" class="review-data-field-match-list">
                <template #cell(cleaned_Email1)="data">
                  <div :class="
                      data.item.cleaned_Email1 != data.item.email1
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.email1 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_Email1,
            data.item.email1,
            'Email 1'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email1 }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Email1_Domain_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email1_Domain_Status }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Email1_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email1_Status }}
                    </div>
                  </div>
                </template>

                <template #cell(cleaned_Email2)="data">
                  <div :class="
                      data.item.cleaned_Email2 != data.item.email2
                        ? 'dashboard-data-review-hightlight'
                        : (data.item.email2 == '' ?
                          'dashboard-data-review-nodata'
                          : 'dashboard-data-review-field-data')" @click="
          displayChangeModal(
            data.item.cleaned_Email2,
            data.item.email2,
            'Email 2'
          )
        ">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email2 }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Email2_Domain_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email2_Domain_Status }}
                    </div>
                  </div>
                </template>
                <template #cell(cleaned_Email2_Status)="data">
                  <div class="dashboard-data-review-field-data">
                    <div class="dashboard-data-review-field-sample  d-flex align-items-center justify-content-center">
                      {{ data.item.cleaned_Email2_Status }}
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="row">
        <br />
      </div>

      <b-modal id="changeModal" v-model="showChangeModal" class="changeDataModal">
        <div class="modal_body">
          <div class="datareviewbemodalheadtitle">
            <h1>{{ modalData.title }}</h1>
          </div>
          <button class="close_button" @click="$bvModal.hide('changeModal')">
            x
          </button>
          <b-row>
            <b-col cols="6">
              <div class="datareviewbemodaltitle">Before</div>
            </b-col>
            <b-col cols="6">
              <div class="datareviewbemodaltitle">After</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="datareviewbeforebox">
                <span v-if="modalData.oldval != ''">{{
                  modalData.oldval
                  }}</span>
                <span v-if="modalData.oldval == ''">Blank</span>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="datareviewafterbox">
                <span v-if="modalData.newval != ''">{{
                  modalData.newval
                  }}</span>
                <span v-if="modalData.newval == ''">Blank</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>

    <div>
      <b-modal id="sampleModal" v-model="showHeatmapModal" class="sampleDataModal" hide-footer>
        <div class="modalbody1">
          <b-row>
            <b-col cols="6">
              <div class="datareviewbemodaltitle">Before</div>
            </b-col>
            <b-col cols="6">
              <div class="datareviewbemodaltitle">After</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="datareviewbeforebox">
                <!-- <span v-if="modalData.oldval!=''">{{modalData.oldval}}</span> -->
                <span>J</span>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="datareviewafterbox">
                <!-- <span v-if="modalData.newval!=''">{{modalData.newval}}</span> -->
                <span>James</span>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="modalbody2">
          <h4>
            <span style="color: rgb(30 109 81)">Introducing</span>
            <span class="text-primary">Heatmap View</span>
          </h4>
          <p>
            Heatmap View is the interactive tool to view all improved data.
            Click on the highlighted fields to see a before and after
            comparison.
          </p>
          <p></p>
          <button type="button" class="btn btn-outline-primary hitmapbutton" @click="$bvModal.hide('sampleModal')">
            Try Heatmap View
          </button>
        </div>
      </b-modal>

      <div class="purchase_modal_section">
        <b-modal id="modal1" v-model="purchaseModal" hide-footer title="Checkout">
          <PayModal :currentTask_id="this.$route.params.id">
          </PayModal>
        </b-modal>
      </div>

      <div class="download_modal_section">
        <b-modal id="modal2" v-model="downloadModalShow" title="Customise your Export" hide-footer>
          <DownloadModal :currentTask_id="this.currentTask.id" :currentTask_title="this.currentTask.title">
          </DownloadModal>
        </b-modal>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

// import { UPDATE_TASKS } from "@/core/services/store/tasks.module";
import { UPDATE_USER_SETTING } from "@/core/services/store/auth.module";

import ApiService from "@/core/services/api.service";
import PayModal from "@/view/pages/PurchaseModal.vue";
import DownloadModal from "@/view/pages/downloadModal.vue";

import Swal from "sweetalert2";


export default {
  name: "dashboard",
  components: {
    PayModal,
    DownloadModal,
  },
  props: ["helpValue", "refreshValue"],
  watch: {
    helpValue: function () {
      // watch it
    },
    refreshValue: function () {
      // watch it
    }
  },
  data() {
    return {
      // Note 'isActive' is left out and will not appear in the rendered table
      filter: "",
      currentTask: null,
      showCurrentTask: false,
      showDataTab: 1,
      showChangeModal: false,
      showHeatmapModal: false,
      isModalVisible: false,
      purchaseModalShow: false,
      downloadModalShow: false,
      modalData: {
        oldval: "",
        newval: "",
        title: ""
      },

      fieldsTab1: [
        {
          key: "cleaned_NameTitle",
          label: "Title",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_NameForename",
          label: "Forename",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_NameInitials",
          label: "Initials",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_NameSurname",
          label: "Surname",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_NameSalutation",
          label: "Name Salutation",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        }
      ],

      fieldsTab2: [
        {
          key: "cleaned_OrganisationName",
          label: "Company Name",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressLine1",
          label: "Address Line 1",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressLine2",
          label: "Address Line 2",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressLine3",
          label: "Address Line 3",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressTown",
          label: "Address Town",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressCounty",
          label: "Address County",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_AddressPostcode",
          label: "Address Postcode",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        }
      ],

      fieldsTab3: [
        {
          key: "cleaned_Telephone1",
          label: "Telephone 1",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone1_Type",
          label: "Telephone 1 Type",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone1_Status",
          label: "Telephone 1 Status",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone2",
          label: "Telephone 2",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone2_Type",
          label: "Telephone 2 Type",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone2_Status",
          label: "Telephone 2 Status",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone3",
          label: "Telephone 3",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone3_Type",
          label: "Telephone 3 Type",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        },
        {
          key: "cleaned_Telephone3_Status",
          label: "Telephone 3 Status",
          sortable: false,
          tdClass: "align-middle dashboard-data-review-field-tick-example-data",
          thClass: "text-center",
        }
      ],

      fieldsTab4: [
        {
          key: "cleaned_Email1",
          label: "Email 1",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        },
        {
          key: "cleaned_Email1_Status",
          label: "Email 1 Status",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        },
        {
          key: "cleaned_Email1_Domain_Status",
          label: "Email 1 DNS Status",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        },
        {
          key: "cleaned_Email2",
          label: "Email 2",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        },
        {
          key: "cleaned_Email2_Status",
          label: "Email 2 Status",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        },
        {
          key: "cleaned_Email2_Domain_Status",
          label: "Email 2 DNS Status",
          sortable: false,
          tdClass: "dashboard-data-review-field-tick-example-data"
        }
      ]
    };
  },
  mounted() {

    if (this.$route.params.section) {
      if (this.$route.params.section == "email") {
        this.showDataTab = 4;
      }
      if (this.$route.params.section == "phone") {
        this.showDataTab = 3;
      }
      if (this.$route.params.section == "address") {
        this.showDataTab = 2;
      }
      if (this.$route.params.section == "contact") {
        this.showDataTab = 1;
      }
    }

    this.getData();
  },
  updated() {

    var onboarding = this.$store.getters.currentUser.userData.onboarding;
    var heatmap = this.$store.getters.currentUser.userData.heatmap;
    var onboarding_flag = this.$store.getters.currentUser.userData.onboarding_flag;
    if (this.$store.getters.currentUser.userData.heatmap == "N") {
      heatmap = "Y";
      this.$store.dispatch(
        UPDATE_USER_SETTING,
        { onboarding, heatmap, onboarding_flag },
        this.$store.getters.currentUser.userData.id
      );
      this.$store.commit('setUserSetting', { onboarding: onboarding, heatmap: heatmap, onboarding_flag: onboarding_flag });
      this.showHeatmapModal = true;
    }
  },
  methods: {
    displayChangeModal(newval, oldval, title) {
      this.modalData.newval = newval;
      this.modalData.oldval = oldval;
      this.modalData.title = title;
      // if (this.modalData.newval != this.modalData.oldval) {
      //   this.showChangeModal = true;
      // }
      this.showChangeModal = true;

    },
    getData() {

      if (this.currentTasks.length) {
        this.currentTasks.forEach((record) => {
          if (record.id == this.$route.params.id) {
            this.currentTask = record;
            this.showCurrentTask = true;
          }
        });
      }

      // this.$store
      //   .dispatch(UPDATE_TASKS, {
      //     filter
      //   })
      //   .then(() => {
      //     this.currentTasks.forEach(record => {
      //       if (record.id == this.$route.params.id) {
      //         this.currentTask = record;
      //         this.showCurrentTask = true;
      //       }
      //     });
      //   });
    },
    downloadSampleFile() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      ApiService.setHeader();
      ApiService.getblob("apps/todo/task/downloadsample/" + this.currentTask.id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          var date1 = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");
          var outputFile = this.currentTask.title;
          outputFile = outputFile + "_RMDCLEANSE_" + date1 + "_SAMPLE.xlsx";
          outputFile = outputFile.replace(".", "_").replace("/", "_");

          fileLink.href = fileURL;
          // fileLink.setAttribute('download', this.currentTask.id + '.xlsx');
          fileLink.setAttribute("download", outputFile);
          document.body.appendChild(fileLink);

          Swal.close();
          fileLink.click();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry Download Error, Try Later",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    downloadFile() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      ApiService.setHeader();
      ApiService.getblob("apps/todo/task/download/" + this.currentTask.id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          var date1 = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");
          var outputFile = this.currentTask.title;
          outputFile = outputFile + "_RMDCLEANSE_" + date1 + ".xlsx";
          outputFile = outputFile.replace(".", "_").replace("/", "_");

          fileLink.href = fileURL;
          // fileLink.setAttribute('download', this.currentTask.id + '.xlsx');
          fileLink.setAttribute("download", outputFile);
          document.body.appendChild(fileLink);

          Swal.close();
          fileLink.click();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry Download Error, Try Later",
            confirmButtonClass: "btn btn-secondary"
          });
        });
    },
    importGoback() {
      this.$router.push({ name: "dashboard" });
    },
    doStats() {
      this.$router.push({
        name: "dashboardreview",
        params: { id: this.currentTask.id }
      });
    },
    doData() {
      this.$router.push({
        name: "dashboardreviewdata",
        params: { id: this.currentTask.id }
      });
    },
    doPay() {
      Swal.fire({
        title: "Please Wait",
        text: "Payment Transaction Processing",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      ApiService.setHeader();
      ApiService.get("apps/todo/task/stripecheckout/" + this.currentTask.id)
        .then(({ data }) => {
          Swal.close();
          window.location = data;

          // <v-alert
          //   type="success"
          // >
          // data
          // </v-alert>

          // Swal.fire({title: "api pay success",
          //            text:data,
          //            confirmButtonClass: "btn btn-secondary",
          //             onOpen: () => { Swal.showLoading(); }})

          // window.location = data;
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title:
              "Sorry we can not take a payment at the moment please try later",
            confirmButtonClass: "btn btn-secondary"
          });
        });

      // this.$router.push({name: 'dashboardreview', params: { id: this.currentTask.id}});
    }
  },
  computed: {
    ...mapGetters(["currentTasks", "currentUser", "isAuthenticated"]),
    ...mapMutations(["setUserSetting"]),

    getMappings() {
      return this.mappings;
    },
    getContacts() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      return currentTaskResults.sampleContactsImproved;
    },
    getPhones() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      return currentTaskResults.samplePhoneValid;
    },
    getAddresses() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      return currentTaskResults.sampleAddressValid;
    },
    getEmails() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      return currentTaskResults.sampleEmailValid;
    }
  }
};
</script>

<style>
.filename {
  /* width: 600px; */
  /* height: 30px; */
  /* flex-wrap: wrap; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* margin-top: 19px;	
  margin-left: 15px; */
  /* identical to box height */
  color: #3D415B;
}

.dashboardreviewdata {
  background-color: #f5f9fd;
}

.dashboardreviewdata .DashboardReview-HeaderRow {
  /* background-color: white; */
  margin-left: 14px;
  margin-top: -25px;
  padding-top: 25px;
}

/* .smallbox-Container {
  min-height: 188px;
  max-height: 188px;
} */

.largebox-Container {
  min-height: 305px;
  max-height: 305px;
}

.review-Helpbutton-Container_old {
  font-size: 12px;
  color: #3f6f86;
  opacity: 0.4;
  float: left;
}

.purchasebutton {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  width: 238px;
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* margin-left: -62px; */
}


.DashboardReviewMatch-DataTitleRow {
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.DashboardReviewMatch-Map-your-Data {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.DashboardReviewMatch-Map-your-Text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  width: 1045px;
  color: #3D415B;
}

.dashboardreviewdatamapp-left-pad {
  margin-left: 0px;
}

.dashboardreviewdata-data-field-text-holder {
  height: 60px;
  margin-top: 20px;
  padding: 15px 169px 19px 33px;
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  font-size: 18px;
  color: black;
}

.dashboardreviewdata-data-field-text-rholder {
  height: 60px;
  margin-top: 20px;
  padding: 15px 169px 19px 33px;
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  font-size: 18px;
  color: black;
}

.review-data-field-match-list {
  border-radius: 12px;
  background: transparent;
  border-radius: 12px;
  border: 0;
}

.review-data-field-match-list thead tr {
  background: #F1F1F1;
}

.review-data-field-match-list tr:last-child th:first-child {
  border-top-left-radius: 10px !important;
}

.review-data-field-match-list tr:last-child th:last-child {
  border-top-right-radius: 10px !important;
}

.review-data-field-match-list td {
  border-top: 0px solid #ebedf3;
  padding: 0;
  border: 0;
  padding: 5px;
  height: 48px;
}

.review-data-field-match-list thead th {
  height: 60px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 20px;
}

.review-data-field-match-list tbody tr:hover {
  background-color: transparent;
}

.dashboard-data-review-field-tick-example-data {
  height: 60px;
  border-radius: 10px;
  margin: 20px 0.5px 12.5px 50px;
  padding: 15px 169px 19px 33px;
  border-radius: 10px;
  border-left: solid 1px #d9d9d9;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  font-size: 18px;
  text-align: center;
  color: #3D415B;
}

.dashboard-data-review-field-sample {
  height: 48px;
  opacity: 1 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #3D415B;
  text-align: center;
  /* padding-top: 15px; */
  z-index: 99;
  word-wrap: break-word;
  border-radius: 10px;
}

.dashboard-data-review-hightlight {
  width: 100%;
  height: 100%;
  background-color: #e2f5ee;
  cursor: pointer;
  border-radius: 10px;
  /* padding-top: 15px; */
}


.dashboard-data-review-nodata {
  width: 100%;
  height: 100%;
  /* background-color: #F1F1F1;  grey color */
  background-color: #ffffff;
  cursor: default;
  border-radius: 10px;
  /* padding-top: 15px; */
}

.dashboard-data-review-field-data {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  cursor: default;
  border-radius: 10px;
  /* padding: 15px; */
}

/* . {
  width: 100%;
  height: 100%;
  background-color: #f6f4f4;
  cursor: pointer;

  border-radius: 10px;
} */

#changeModal header {
  display: none;
}

#changeModal footer {
  display: none;
}

#changeModal .modal-body {
  border-radius: 10px;
  background-color: #e9eef3;
}

#changeModal .row {
  height: 70px;
  font-size: 14px;
}

.datareviewbeforebox {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  border: solid 1px rgb(112, 112, 112, 0.14);
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 25px;
  word-wrap: break-word;
}

.datareviewafterbox {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  background-color: rgb(51, 185, 133, 0.14);
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 25px;
  word-wrap: break-word;
}

.datareviewbemodalheadtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.datareviewbemodaltitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-top: 25px;
  opacity: 0.9;
  font-size: 13px;
  font-weight: 500;
  color: #3f6f86;
}

.datareviewDataselectionBox {
  margin-top: 20px;
  /* padding: 20px; */
  border-radius: 12px;
  /* background-color: white; */
}

.datareviewselectradiobuttons {
  color: #62657D;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.8;
  width: 100%;
  margin-bottom: 20px;
}

/* .datareviewselectradiobuttons .custom-control-inline {
  padding-left: 20%;
} */

.radiohighlighttext label {
  color: #2C9A65;
}

.Dashboard-Readr-Campaign-Dataxls {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3f6f86;
  line-height: 70px;
}

.modalbody1 {
  padding: 66px 16px;
}

.modalbody2 {
  margin-top: 30px;
  background-color: white;
  margin: -23px -20px;
  padding: 62px 0px;
}

.modalbody2 h4 {
  text-align: center;
  font-weight: 600;
}

.modalbody2 p {
  text-align: justify;
  line-height: 1.8;
  width: 327px;
  margin: auto;
}

.hitmapbutton {
  margin: 25px 150px;
  width: 180px;
}

.modal .modal-header .close {
  color: #1d1d1ee6;
  font-size: 50px;
}

.modal-body {
  background-color: #7e82993b;
}

.modal .modal-header {
  background-color: #7e82993b;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

#sampleModal .modal-dialog {
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  margin: 0 auto;
}

#changeModal .modal-dialog {
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  margin: 0 auto;
}

.modal .modal-header .close {
  color: #1d1d1ee6;
  font-size: 50px;
}

.close_button {
  align-items: end;
  float: right;
  margin-top: -57px;
  font-size: 35px;
  color: rgb(111, 108, 108);
}


.table-container {
  /* position: relative; */
  /* overflow: hidden; */
  height: 330px;
  overflow-y: auto;
  display: flex;
}

.custom-b-table-class th {
  position: sticky !important;
  top: 0;
}
</style>


<style scoped>
.table.b-table>thead>tr>.table-b-table-default {
  background-color: #535151;
}

.table-body {
  scrollbar-width: thin;
  /* or none */
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 310px;
}

.scroll-content {
  width: 100%;
  height: 100%;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #CCD2E3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: #606060;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dashboarmapp {
  background-color: #F8F8F9 !important;
  display: flex;
  flex-direction: column;
}

.matchbox {
  width: 64px;
  border: 2px dashed rgb(149, 186, 242);
  margin-left: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.backbutton {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.modal .modal-header .modal-title {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}
</style>
